.burger {
	position: fixed;
	right: 10px;
	top: 10px;
	z-index: 2;
	border: 2px solid rgba(4, 26, 93, 0.183);
	padding-inline: 12px;
	border-radius: 10px;
	display: none;
	@media (max-width: 1024px) {
		display: block;
	}
}
.burger-nav {
	height: 50px;
	width: 100%;
	z-index: 5000000;
	display: flex;
	align-items: center;
}

.burger-btn {
	width: 25px;
	height: 25px;
	position: relative;
	cursor: pointer;
	z-index: 3;

	span {
		position: absolute;
		top: 11px;
		width: 25px;

		background: rgba(4, 26, 93, 0.5);

		height: 3px;
	}
}

.burger-btn:before {
	content: "";
	position: absolute;
	top: 0;
	width: 25px;
	background: rgba(4, 26, 93, 0.5);
	height: 3px;
}
.burger-btn::after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 25px;
	background: rgba(4, 26, 93, 0.5);
	height: 3px;
}
