.icon-up {
	display: flex;
	align-items: center;
	opacity: 0.5;
	position: fixed;
	right: 30px;
	bottom: 60px;
	padding: 15px 10px 10px;
	background: #aaa;
	-webkit-border-radius: 5px;
	cursor: pointer;
	z-index: 1;

	@media (max-width: 1024px) {
		right: 10px;
		bottom: 20px;
	}

	&:hover {
		opacity: 0.3;
	}
	img {
		width: 84px;
		height: 79px;

		@media (max-width: 1024px) {
			width: 44px;
			height: 39px;
		}
	}
}
