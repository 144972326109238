.benefits {
	padding-block: 100px;

	@media (max-width: 1024px) {
		padding-block: 50px;
	}
}

.benefits-title {
	color: #000;
	text-align: center;
	margin-bottom: 50px;
	@media (max-width: 1024px) {
		font-size: 22px;
	}
}
.benefits-text-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 30px;
	row-gap: 60px;
	@media (max-width: 550px) {
		column-gap: 30px;
		row-gap: 30px;
	}
}

.benefits-text {
	color: #000;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	flex: 1 1 330px;

	svg {
		border-radius: 100%;
		width: 200px;
		height: 100px;
		opacity: 0.8;
		@media (max-width: 550px) {
			width: 75px;
			height: 25px;
		}
	}

	p {
		font-size: 24px;
		@media (max-width: 1024px) {
			font-size: 16px;
		}
	}
}

.benefits-etaps-img {
	display: flex;
	align-items: center;
	border: 2px solid rgba(4, 26, 93, 0.183);
	border-radius: 100%;
	padding-block: 20px;
	padding-inline: 25px;
	margin-bottom: 15px;
}
