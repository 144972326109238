@import "../../assets//Styles/mixins.scss";

.header {
	font-family: system-ui;
	padding-block: 20px;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-image: linear-gradient(
			to bottom,
			rgba(146, 187, 235, 0.8),
			rgba(8, 29, 31, 0.7)
		),
		url(../../assets/Images/fon.jpg);
}

.header-logo {
	margin-top: -20px;
	@media (max-width: 1024px) {
		margin-top: -10px;
	}

	svg {
		width: 220px;
		height: 81px;
		@media (max-width: 1024px) {
			width: 120px;
			height: 41px;
		}
	}
}

.header-arrow-wrapper-desktop {
	svg {
		padding-top: 110px;
	}
}

.header-bot-desktop-p {
	margin-top: -110px !important;
}

.header-top-info {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	color: white;
}

.header-top-info-line {
	border-bottom: 1px solid #ffffff45;
	margin-bottom: 15px;
}

.header-top-info-tel-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-size: 22px;

	a {
		cursor: pointer;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		text-decoration: none;
		color: #fff;
		&:hover {
			opacity: 0.5;
		}
	}

	@media (max-width: 1024px) {
		display: none;
	}
}

.header-top-info-icons {
	display: flex;
	gap: 20px;
	padding-left: 6px;
	margin: 0 auto;

	svg {
		width: 20px;
		height: 20px;
	}
}

.header-top-info-location-wrapper {
	@media (max-width: 1024px) {
		display: none;
	}
}
.header-top-info-location {
	display: flex;

	gap: 10px;
	font-size: 22px;

	a {
		text-decoration: none;
		color: #fff;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.5;
		}
	}

	svg {
		margin-top: 8px;

		width: 20px;
		height: 20px;
	}
}

.header-top-info-mail {
	display: flex;

	gap: 10px;
	font-size: 22px;

	a {
		text-decoration: none;
		color: white;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.5;
		}
	}

	svg {
		margin-top: 7px;
		width: 20px;
		height: 20px;
	}

	@media (max-width: 1024px) {
		display: none;
	}
}

.header-top-wrapper {
	display: flex;
}

.header-left {
	display: flex;
	justify-content: center;
	gap: 180px;
	margin-bottom: 50px;

	a {
		color: #ffffff;

		font-weight: 600;
		outline: none;

		position: relative;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
		opacity: 1;
		text-decoration: none;

		&:hover {
			opacity: 0.5;

			// -webkit-box-shadow: inset 0px -1px 0px 0px rgb(120, 195, 249);
			// -moz-box-shadow: inset 0px -1px 0px 0px rgb(120, 195, 249);
			// box-shadow: inset 0px -2px 0px 0px rgb(120, 195, 249);
		}
	}

	@media (max-width: 1024px) {
		display: none;
	}
}
.header-center {
	a {
		font-size: 24px;
		color: #ffffff;
	}
}

.header-center-p {
	max-width: 660px;
}

.header-bot {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 36px;
	color: #fff;
	margin-bottom: 100px;
	display: none;

	@media (max-width: 1024px) {
		font-size: 30px;
	}

	@media (max-width: 960px) {
		display: flex;
		font-size: 23px;
	}

	@media (max-width: 550px) {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		padding-inline: 15px;
		font-size: 26px;
	}

	svg {
		width: 132px;
		height: 120px;
		opacity: 0.7;
		z-index: 1;

		@media (max-width: 746px) {
			width: 84px;
			height: 90px;
		}
	}

	a {
		max-width: 340px;
		max-height: 92px;
		padding: 26px;
		font-size: 34px;
		color: #fff;
		background: none;
		border: 1px solid #fff;
		border-radius: 10px;

		text-decoration: none;

		cursor: pointer;

		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.5;
		}

		@media (max-width: 1024px) {
			max-width: 97px;
			max-height: 56px;
			padding: 20px;
			font-size: 17px;
		}

		@media (max-width: 746px) {
			max-width: 66px;
			max-height: 42px;
			padding: 15px;
			font-size: 12px;
		}

		@media (max-width: 550px) {
			max-width: 66px;
			max-height: 42px;
			padding: 26px;
			font-size: 12px;
		}
	}
}

.header-arrow-wrapper {
	padding-top: 40px;
	display: flex;
	gap: 8px;
	// @media (max-width: 550px) {
	// 	padding-top: 30px;
	// }

	// @media (max-width: 960px) {
	// 	svg {
	// 		display: none;
	// 	}
	// }
}

.header-arrow-button {
	padding-top: 48px;
}

.header-center-wrapper {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	text-align: center;
	max-width: 683px;
	color: rgb(255, 255, 255);
	font-size: 24px;
	line-height: 1.5;
	margin-bottom: 100px;

	@media (max-width: 550px) {
		margin-bottom: 20px;
	}
}

.header-center-title {
	letter-spacing: 0.5px;
	font-weight: 600;
	font-size: 65px;

	@media (max-width: 550px) {
		font-size: 40px;
	}
}

// Stuff that matters.
@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(60px);
	}
}

svg #wheel {
	animation: scroll ease 2s infinite;
}

.header-scroll {
	max-width: 10px;
	padding-left: 300px;

	cursor: pointer;
}

.telegram-footer {
	display: flex;
	align-items: center;
	opacity: 0.5;
	position: fixed;
	right: 30px;
	bottom: 190px;
	padding: 15px 10px 10px;
	background: #aaa;
	-webkit-border-radius: 5px;
	cursor: pointer;
	z-index: 1;

	@media (max-width: 1024px) {
		right: 10px;
		bottom: 100px;
	}

	&:hover {
		opacity: 0.3;
	}
	svg {
		width: 84px;
		height: 79px;

		@media (max-width: 1024px) {
			width: 44px;
			height: 39px;
		}
	}
}

.header-bot-desktop {
	display: flex;
	@media (max-width: 960px) {
		display: none;
	}

	@media (max-width: 960px) {
		padding-top: 150px;
		flex-direction: column;
	}

	@media (max-width: 550px) {
		text-align: center;
		padding-top: 200px;
		flex-direction: column;
	}

	p {
		margin-top: -200px;
	}
}
