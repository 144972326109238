.menu {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 50px;
	left: 0;
    transform: translateX(-150%);
    // .active{
    //     transform: translateX(0);
    //     left:300px;
    // }
}

.menu.active{
    transform: translateX(0);

}


.blur {
	width: 100vw;
	height: 100vh;
	backdrop-filter: blur(2px);
	position: absolute;
    left: 30%;
}

.menu-content {
    // margin-top: -50px;
    margin-top: -50px;
	width: 100%;
	height: 100%;
	// background: #fff;
    background-image: linear-gradient(
			to bottom,
			rgba(146, 187, 235, 0.8),
			rgba(8, 29, 31, 0.7)
		),
		url(../../assets/Images/fon.jpg);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-header{
    padding: 10px;
    padding-top: 85px;
    color: #fff;
    font-size: 32px;
    // border-bottom:1px solid red;
    width: 100%;
    text-align: center;
    // margin-bottom: 10px;
}


ul li{
    display: flex;
    align-items: center;
    margin: 10px 0;

}

ul li a {
    color:#fff;
    text-decoration: none;
    font-size: 24px;

}

ul{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2705882353);
    width: 100%;
    padding-top: 20px;
    padding-right: 35px;

}

