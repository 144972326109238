.benefits-background {
	background-image: linear-gradient(
			to bottom,
			rgba(146, 187, 235, 0.8),
			rgba(8, 29, 31, 0.7)
		),
		url(../../assets/Images/bg-footer2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.input-wrapper-form-mobile {
	display: none;

	@media (max-width: 961px) {
		display: block;
	}
}

.analiz {
	font-size: 36px;
	padding-bottom: 100px;

	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 36px;
	color: #fff;

	@media (max-width: 1024px) {
		font-size: 30px;
		margin-bottom: 50px;
	}
	// @media (max-width: 830px) {
	// 	font-size: 20px;
	// 	margin-bottom: 50px;
	// }
	// @media (max-width: 550px) {
	// 	display: flex;
	// 	flex-direction: column;
	// 	padding-top: 50px;
	// 	padding-bottom: 50px;
	// }

	@media (max-width: 960px) {
		// padding-top: 190px;
		padding-top: 50px;
		flex-direction: column;
		text-align: center;

		// svg {
		// 	display: none;
		// }
	}

	p {
		margin-top: -90px;
		@media (max-width: 960px) {
			margin-top: 0;
		}
	}

	svg {
		width: 132px;
		height: 120px;
		opacity: 0.7;

		@media (max-width: 830px) {
			width: 84px;
			height: 90px;
		}
	}

	a {
		max-width: 340px;
		max-height: 92px;
		padding: 26px;
		font-size: 34px;
		color: #fff;
		background: none;
		border: 1px solid #fff;
		border-radius: 10px;
		text-decoration: none;

		cursor: pointer;

		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.5;
		}

		@media (max-width: 1024px) {
			max-width: 97px;
			max-height: 56px;
			padding: 20px;
			font-size: 17px;
		}
		@media (max-width: 830px) {
			max-width: 66px;
			max-height: 42px;
			padding: 11px;
			font-size: 12px;
		}
		@media (max-width: 550px) {
			max-width: 76px;
			max-height: 42px;
			padding: 20px;
			font-size: 12px;
		}
	}
}

.input-wrapper-form-desktop {
	margin-top: -120px !important;
}

.analiz-desktop {
	@media (max-width: 960px) {
		display: none;
	}
}

.analiz-arrow-wrapper {
	padding-top: 280px;
	display: flex;
	gap: 6px;
	

	@media (max-width: 960px) {
		padding-top: 40px;

		// svg {
		// 	display: none;
		// }
	}

	@media (max-width: 550px) {
		padding-top: 20px;
	}
}

.analiz-desktop-arrow-wrapper {
	padding-top: 320px;
	display: flex;
	gap: 20px;
	@media (max-width: 550px) {
		padding-top: 20px;
	}

	@media (max-width: 960px) {
		padding-top: 190px;
		// flex-direction: column;

		svg {
			display: none;
		}
	}

	svg {
		// padding-top: 84px;
	}
}

.analiz-arrow-button {
	padding-top: 48px;
}

.input__field {
	border-radius: 12px;
	max-width: 250px;
	height: 46px !important;
	padding: 18px 16px 18px 58px;
	color: #000;
	background-color: none;

	&.invalid-number {
		border: none;
		box-shadow: none;

		&:hover,
		&:active,
		&:focus {
			// background-color: greenyellow;
			// border: 1px solid greenyellow;
			// border:1px solid red;
		}
	}

	&::placeholder {
		color: #000;
	}

	&:hover {
		background-color: #fff;
		border-color: #fff !important;
	}

	&:active {
		border-color: #fff !important;
	}

	&:focus {
		background-color: #fff;
		border-color: none;
	}

	&.invalid {
		background-color: red;
	}
	&.valid {
		background-color: red;
	}
}

.form-wrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;

	border: 2px solid rgba(255, 255, 255, 0.516);
	background: rgba(0, 0, 0, 0.189);
	border-radius: 10px;
	padding: 20px;
}

.input-wrapper-form {
	margin: 0 auto;
	max-width: 900px;

	// @media (max-width: 961px) {
	// 	display: none;
	// }

	button {
		max-width: 340px;
		max-height: 124px;
		padding: 26px;
		font-size: 30px;
		color: #fff;
		background: none;
		border: 1px solid #fff;
		border-radius: 10px;
		text-decoration: none;

		cursor: pointer;

		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.5;
		}
	}
}

.input-mail {
	position: relative;
	font-size: 14px;
	letter-spacing: 0.01rem;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	// padding-left: 48px;
	padding-left: 10px;
	margin-left: 0;
	background: #ffffff;
	border: 1px solid #cacaca;
	border-radius: 5px;
	line-height: 25px;
	height: 35px;
	width: 300px;
	outline: none;
}

.input-text-wrapper {
	display: flex;
	flex-direction: column;

	gap: 20px;

	input {
		height: 40px;
		width: 238px;

		font-family: system-ui;
	}

	textarea {
		height: 50px;
		width: 230px;
		padding: 10px;
		outline: none;
		border-radius: 5px;
		font-size: 14px;
		font-family: system-ui;
		resize: none;

		@media (max-width: 961px) {
			width: 236px;
		}
	}
}

.testgg {
	height: 30px;
	border: 1px solid red;
	margin-bottom: 10px;
}
