@import "../../assets/Styles/mixins.scss";

.footer-background {
	background-image: linear-gradient(
			to bottom,
			rgba(146, 187, 235, 0.8),
			rgba(8, 29, 31, 0.7)
		),
		url(../../assets/Images/bg-footer.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	color: #fff;
	padding-bottom: 100px;

	@media (max-width: 1024px) {
		padding-bottom: 50px;
	}
}

.arrow-fix-footer{
	padding-top: 189px;

	@media (max-width: 960px) {

		svg {
			display: none;
		}
	}
}

.footer-top-info-line {
	border-top: 1px solid #ffffff45;
	padding-top: 15px;
}

.footer-top-info {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 10px;
	color: white;

	@media (max-width: 920px) {
		justify-content: center;
	}
}

.footer-logo {
	margin-top: -20px;

	svg {
		width: 220px;
		height: 81px;
	}
}

.footer-top-info-location-wrapper {
}

.footer-top-info-location {
	display: flex;

	gap: 10px;
	font-size: 22px;

	a {
		text-decoration: none;
		color: #fff;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.5;
		}
	}

	svg {
		margin-top: 7px;

		width: 20px;
		height: 20px;

		@media (max-width: 960px) {
			margin-top: 4px;
		}
	}
}

.footer-top-info-tel-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-size: 22px;

	a {
		cursor: pointer;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		text-decoration: none;
		color: #fff;
		&:hover {
			opacity: 0.5;
		}
	}
}

.footer-top-info-icons {
	display: flex;
	gap: 20px;
	padding-left: 6px;
	margin: 0 auto;

	svg {
		width: 20px;
		height: 20px;
	}
}

.footer-top-info-mail {
	display: flex;

	gap: 10px;
	font-size: 22px;

	a {
		text-decoration: none;
		color: white;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.5;
		}
	}

	svg {
		margin-top: 7px;

		width: 20px;
		height: 20px;
		display: flex;

		@media (max-width: 960px) {
			margin-top: 4px;
		}
	}
}

.footer-copyright {
	margin-top: 60px;
	text-align: center;
	@media (max-width: 1024px) {
		margin-top: 30px;
	}
}

.footer-desktop-form {
	display: flex;
	padding-inline: 10px;

	@media (max-width: 960px) {
		// display: none;
		flex-direction: column;
		padding-top: 50px;
	}
}

.footer-mobile-form {
	display: none;

	@media (max-width: 960px) {
		display: flex;
	}
}
