.pros-background {
	background-color: #ededed;
	padding-block: 100px;

	@media (max-width: 1024px) {
		padding-block: 50px;
	}
}

.pros-title {
	text-align: center;
	margin-bottom: 50px;
	@media (max-width: 1024px) {
		font-size: 22px;
	}
}

.pros {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	padding-inline: 50px;

	p {
		max-width: 260px;

		@media (max-width: 1024px) {
			max-width: 339px;
		}
		@media (max-width: 550px) {
			max-width: 200px;
		}
		@media (max-width: 400px) {
			max-width: 134px;
		}
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 50px;
	}
}

.pro-left {
	display: flex;
	flex-direction: column;
	gap: 30px;

	svg {
		width: 64px;
		height: 64px;
		@media (max-width: 1116px) {
			width: 54px;
			height: 54px;
		}
		@media (max-width: 1024px) {
			width: 50px;
			height: 50px;
		}
	}
}

.pro-left-text {
	display: flex;
	align-items: center;
	gap: 30px;
}

.pro-right-text {
	display: flex;
	align-items: center;
	gap: 30px;

	@media (max-width: 1024px) {
		flex-direction: row-reverse;
	}
}

.pros-midle {
	img {
		border-radius: 50%;
		width: 250px;
		height: 250px;
		box-shadow: 0 0 10px #444;

		@media (max-width: 1116px) {
			width: 200px;
			height: 200px;
		}
		@media (max-width: 1024px) {
			width: 150px;
			height: 150px;
		}
	}
}

.pros-right {
	display: flex;
	flex-direction: column;
	gap: 30px;
	svg {
		width: 64px;
		height: 64px;

		@media (max-width: 1116px) {
			width: 54px;
			height: 54px;
		}
		@media (max-width: 1024px) {
			width: 50px;
			height: 50px;
		}
	}
}

.color-radius {
	border: 3.5px solid #27459e;
	border-radius: 100%;
	padding: 7px;

	@media (max-width: 1116px) {
		padding: 5px;
	}

	svg {
		width: 40px;
		height: 40px;

		@media (max-width: 1116px) {
			width: 35px;
			height: 35px;
		}
	}
}
